(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name login.login.controller:LoginCtrl
   *
   * @description
   *
   */
  angular
    .module('login.login')
    .controller('LoginCtrl', LoginCtrl);

  function LoginCtrl($rootScope, $state, Restangular, AuthTokenFactory) {
    var vm = this;
    vm.ctrlName = 'LoginCtrl';
    vm.login = login;
    vm.errorMessage = '';
    vm.form = {
      identifier: '',
      password: ''
    };

    function login() {
      Restangular.all('login').post(vm.form).then(function (response) {
        console.log('login successful', response.token);

        AuthTokenFactory.setToken(response.token, response.role);
        $rootScope.role = response.role;

        if (angular.isObject($rootScope.stateAfterLogin) && $rootScope.stateAfterLogin !== {}) {

          console.log('Redirecting to original page');
          $state.go($rootScope.stateAfterLogin);
          $rootScope.stateAfterLogin = null;
        } else {
          $state.go('people.list');
        }

      }, function (error) {
        if (error.status == -1) {
          vm.errorMessage = 'Could not connect to the server'
        } else if (error.status == 401) {
          vm.errorMessage = 'Incorrect username or password'
        } else {
          vm.errorMessage = error.data.errors[0].message;
        }
      });
    }
  }
}());
